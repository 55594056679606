import { Title, Video } from '~/components'

export function Review() {
  return (
    <div>
      <Title label="Rückblick" spacing="xlarge">
        Das war 2023!
      </Title>
      <Video
        src="https://a.storyblok.com/f/123006/x/521599648d/next-step_2023_web.mp4"
        preview="https://a.storyblok.com/f/123006/1920x1078/e7b8b32bb1/next-step_2022_web.jpg"
      />
    </div>
  )
}
